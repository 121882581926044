import React from "react";
import BgImg from "assets/career_hero_bg.inline.svg";

const Image = (props: React.ComponentProps<"div">) => (
  <div {...props} className={`relative overflow-hidden ${props.className}`}>
    <BgImg
      preserveAspectRatio="xMidYMin meet"
      className="
        absolute right-1/2 h-full
        w-[110%] translate-x-1/2 opacity-60
        xl:inset-0 xl:h-min xl:min-h-full
        xl:w-min xl:min-w-full xl:translate-x-0"
    />
  </div>
);

export default Image;
